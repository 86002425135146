import styled from "styled-components"
import ReactGA from 'react-ga4';

import { colors } from "../theme.config"
import { useTranslation } from "../hooks/use-translation"
import { useEffect } from "react"
import { SocialLinks } from "../components/social-links"
import { PrimaryLink } from "../components/links/primary-link"
import { RocketseatIcon } from "../components/icons"
import { SecondaryLink } from "../components/links/secondary-link"
import { useLocation } from "react-router-dom";
import { BornToCodeIcon } from "../components/icons/born-to-code";
import { Header } from "../components/header/header";

const Container = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;

  gap: 24px;

  background-color: ${colors.background};

  .subtitle {
    color: ${colors.secondaryText};
    max-width: 500px;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    
    a {
      text-decoration: none;
      color: ${colors.secondaryText};
      cursor: pointer;
    }
  }

  h3 {
    font-size: 14px;
    line-height: 18px;
    font-style: italic;
    color: white;
    margin: 0;
  }

  .title {
    text-align: center;

    h1 {
      font-size: 16px;
      color: white;
      font-weight: 600;
    }

    h2 {
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      font-weight: 300;
      color: white;
      margin: 0;
      color: ${colors.secondaryText};
      opacity: 80%;
    }
  }
`

const PictureContainer = styled.div`
  img {
    object-fit: contain;
    height: 150px;
    width: 150px;
    border-radius: 50%;

    @media (min-width: 768px) {
      height: 200px;
      width: 200px;
    }
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  a {
    cursor: pointer;
  }
`

const primaryButtons = [
  {
    title: "Seja membro do canal",
    subtitle: "Tenha acesso a vídeos, lives e uma comunidade exclusiva!",
    icon: undefined,
    link: "https://www.youtube.com/channel/UCpKvMmsF6QrkVr_zWaLGK-A/join",
    analyticsEvent: "click_membro_canal_primary_button"
  }
]

const secondaryButtons = [
  // {
  //   title: "Assine a Rocketseat (10% OFF) 🚀",
  //   subtitle: "Trilhas de Java, React, Node JS, Go, Python, DevOps e muito mais!",
  //   icon: <RocketseatIcon />,
  //   important: false,
  //   showIcon: false,
  //   link: "https://www.rocketseat.com.br/one?utm_source=influencer&utm_medium=publipost&utm_campaign=lead&utm_content=lead-one-publipost-organic-cupom_KIPPERDEV-none-none-none-none-redes_kipperdev&referral=kipperdev&coupon=KIPPERDEV",
  //   analyticsEvent: "click_one"
  // },
  {
    title: "DESCONTO MÊS DO PROGRAMADOR na Rocketseat!",
    subtitle: "66% OFF no combo de Java, DevOps e IA!",
    icon: <RocketseatIcon />,
    important: false,
    showIcon: false,
    link: "https://www.rocketseat.com.br/oferta/mes-do-programador/kipper",
    analyticsEvent: "click_one"
  },
  {
    title: "Camisetas e Canecas (10% OFF) ✨",
    subtitle: "BornToCode com 10% OFF usando o cupom KIPPERDEV",
    icon: <BornToCodeIcon />,
    important: false,
    showIcon: false,
    link: "https://www.borntocode.com.br",
    analyticsEvent: "click_borntocode"
  },
  {
    title: "Hospede seu site na Hostinger 🛜",
    subtitle: "Hospede seu site com desconto usando o cupom KIPPERDEV",
    link: "https://hostinger.com.br/kipperdev",
    analyticsEvent: "click_hostinger"
  },
  {
    title: "Aprenda Inglês (47% OFF) 🇺🇸",
    subtitle: "Desconto em todos os planos da FluencyPass",
    link: "https://fluencypass.com/plans?utm_campaign=pap&affiliate=kipper&a_bid=1383b569&utm_source=instagram&utm_medium=bio",
    analyticsEvent: "click_fluencypass"
  },
  {
    title: "✨ Itens para o setup ✨",
    subtitle: "Todos os itens que uso no meu setup com descontin!",
    link: "https://sandwiche.me/kipper.dev",
    analyticsEvent: "click_setup_general"
  },
  {
    title: "Contato para parcerias 📧",
    subtitle: "Entre em contato com a nossa equipe para detalhes",
    link: "mailto:contato@fernandakipper.com",
    analyticsEvent: "click_parcerias"
  },
  {
    title: "Eventos de tecnologia 🇧🇷",
    subtitle: "Próximos eventos tech que irão rolar no Brasil, cupons de desconto e mais",
    link: "https://eventostec.com.br/",
    analyticsEvent: "click_eventos_tec"
  },
  {
    title: "Canal do discord 🌍",
    subtitle: "Entre para a nossa comunidade e fique por dentro de novidades",
    link: "https://discord.gg/D5sStBByFr",
    analyticsEvent: "click_discord"
  }
]

const tabs = [
  {
    title: "Home",
    href: "/",
    selected: true
  },
  {
    title: "Blog",
    href: "https://blog.fernandakipper.com/",
    selected: false
  },
  {
    title: "Eventos",
    href: "https://eventostec.com.br/",
    selected: false
  },
]

export function LinkPage() {
  const location = useLocation()
  const { translate, setLang } = useTranslation();

  useEffect(() => {
    setLang('pt')

    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Página de Link" });
  }, [])

  return (
    <Container>
      <Header tabs={tabs}/>
      <PictureContainer>
        <img src="./assets/me-dark-bg.png" alt="Fernanda Kipper Sorrindo" />
      </PictureContainer>
      <div className="title">
        <h1>Fernanda Kipper</h1>
        <h2>@kipper.dev</h2>
      </div>
      <p className="subtitle">👩🏽‍💻 Software Engineer <a href="https://www.mercadolivre.com.br/">@mercadolivre</a><br/>
      🏆 Microsoft MVP <br/>
      😊 Conteúdos tech feitos com muito carinho</p>
      <ButtonsWrapper>
        {primaryButtons.map(btn =>
          <PrimaryLink
            key={btn.link}
            title={btn.title}
            subtitle={btn.subtitle}
            icon={btn.icon}
            link={btn.link}
            analyticsLabel={btn.analyticsEvent}
          />)}
        <h3>{translate("DISCOUNT_SECTION_TITLE")}👇🏻</h3>
        {secondaryButtons.map(btn =>
          <SecondaryLink
            key={btn.link}
            subtitle={btn.subtitle}
            title={btn.title}
            link={btn.link}
            analyticsLabel={btn.analyticsEvent}
          />)}
      </ButtonsWrapper>
      <SocialLinks showTikTok />
    </Container>
  )
}